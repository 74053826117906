<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Dashboard</router-link> / Add Voucher
        </h1>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md8 class="pa-3">
            <p class="mb-1 uppercase primary--text">Title</p>
            <v-text-field
              label="Promotion Title"
              single-line
              outlined
              v-model="title"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 lg4 class="pa-3">
            <p class="mb-1 uppercase primary--text">Voucher Type</p>
            <v-select
              label="Select Voucher"
              single-line
              outlined
              v-model="voucherType"
              :items="voucherTypes"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4 lg4 class="pa-3">
            <p class="mb-1 uppercase primary--text">Code</p>
            <v-text-field
              label="Ex. ACFLAT100"
              single-line
              outlined
              v-model="code"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 lg4 class="pa-3">
            <p class="mb-1 uppercase primary--text">Discount</p>
            <v-text-field
              label="Discount amount"
              single-line
              outlined
              v-model="discount"
              :suffix="getDiscountSuffix()"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 lg4 class="pa-3">
            <p class="mb-1 uppercase primary--text">Type</p>
            <v-select
              label="Select Type"
              single-line
              outlined
              v-model="discountType"
              :items="discountTypes"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4 class="pa-3">
            <p class="mb-1 uppercase primary--text">Valid From</p>
            <v-menu
              v-model="menuFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  label=""
                  single-line
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                @input="menuFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 md4 lg4 class="pa-3">
            <p class="mb-1 uppercase primary--text">Expires In</p>
            <v-text-field
              label="Expires in"
              single-line
              outlined
              v-model="expiry"
              suffix="Days"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 lg4 class="pa-3">
            <p class="mb-1 uppercase primary--text">Expiry Date</p>
            <v-text-field
              disabled
              label="Expires in"
              single-line
              outlined
              :value="getExpiryDate"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 class="pa-3">
            <p class="mb-1 uppercase primary--text">Voucher Details</p>
            <v-textarea
              label="Voucher Details"
              single-line
              outlined
              v-model="details"
            ></v-textarea>
          </v-flex>
          <v-flex xs4 class="pa-3 mt-5">
            <v-btn
              large
              block
              class="primary black--text"
              @click="createPromotion"
              >Submit</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
    
<script>
import { BASE_URL } from "@/config";
import Axios from "axios";

export default {
  data() {
    return {
      discountType: "% discount",
      voucherType: "",
      voucherTypes: [
        "Platform Voucher",
        "Influencer Voucher",
        "Season Voucher",
      ],
      discountTypes: ["Flat discount", "% discount"],
      dateFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      expiry: "30",
      expiryDate: "",
      menuFrom: false,
      title: "",
      code: "",
      discount: "",
      details: "",
    };
  },
  computed: {
    getExpiryDate() {
      const startDate = new Date(this.dateFrom);

      // Add the expiry days to the startDate in milliseconds (avoid overflow)
      startDate.setTime(
        startDate.getTime() + this.expiry * 24 * 60 * 60 * 1000
      );

      // Format the expiry date in YYYY-MM-DD
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, "0");
      const day = String(startDate.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
  },
  methods: {
    getDiscountSuffix() {
      if (this.discountType == "% discount") {
        return "%";
      } else {
        return "AED";
      }
    },
    async createPromotion() {
      let url = BASE_URL + "/promotion";
      let payload = {
        title: this.title,
        type: this.type,
        code: this.code,
        discount: this.discount,
        discount_type: this.discountType,
        valid_from: this.dateFrom,
        expires_in: this.expiry,
        expiry_date: this.getExpiryDate,
        details: this.details,
        status: true,
      };
      console.log(JSON.stringify(payload));
      let { data } = await Axios.post(url, payload);
      alert(JSON.stringify(data));
    },
  },
};
</script>
  
  <style>
.v-text-field__suffix {
  color: #ba9d73 !important;
}
</style>